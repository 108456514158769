@import 'globals.scss';

.my-first-loading {
}

.my-first-loading .loading-screen {
  position: fixed;
  left: 50%;
  top: 50%;
  height: 18em;
  margin-top: -9em;
  margin-left: -15em;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #000000;
  width: 25px;
  height: 25px;
  margin: 0 auto;
  animation: spin 1s linear infinite;
}

.my-first-loading .loading-error {
  padding: 5%;
}
