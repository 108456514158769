@import 'globals.scss';


/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

#root {
  --defaultTransitionDuration: 0.4s;
  --defaultTransition: all var(--defaultTransitionDuration);
  --defaultVisibilityTransition: opacity var(--defaultTransition), visibility var(--defaultTransition);

  --scrollerMarginLeft: 55px;
  white-space: pre-line;
}

/* fonts */
@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);
body {
  font-family: "FuturaLT", "Noto Sans TC", sans-serif;
}
/* end of fonts */
/* lang switch */
.lang-switch {
  position: fixed;
  top: 0;
  left: 50px;
  z-index: 10;
}
.wow {
  opacity: 0;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  background: #fff;
  color: #000;
  margin: 0 auto;
  position: relative;
  // white-space: pre-line;  
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  /* //TODO */
}

a {
  color: #000;
  transition: 0.5s;
}

a:hover, a:active, a:focus {
  /*color: #FFE000;*/
  color: #000000;
  outline: none;
  text-decoration: underline;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1, h2, h3, h4, h5, h6 {  
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

/* Back to top button */

.back-to-top {
  position: fixed;
  display: none;
  background: #18d26e;
  color: #fff;
  display: inline-block;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 50%;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 11;
}

.back-to-top i {
  padding-top: 12px;
  color: #fff;
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }
}

/*
Modal
*/

.ReactModalPortal {
  background: rgba(0,0,0,0.75);
}


.ReactModalPortal div {
  z-index: 1999;
}

.ReactModalPortal div div{
  background: rgba(0,0,0,0.75);
  width: 100%;
  height: 100%;
}

.ReactModalPortal div div.vid-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.75);
  display: flex;
  align-items: center;
}


.vid-player {
  /*width: calc(80vh*16/9);*/
  max-width: 100vw;
  max-height: 100vh;
  min-width: 80vw;
  min-height: 80vh;
}

/*
.vid-player div video {
  position: relative;
  display: block;
  width: 80% !important;
  height: 90% !important;
  margin: 1% 10%;

}
*/

.video-close-btn {
  border-radius: 50%;
  border-width: 1;
  cursor: pointer;
  position: absolute;
  width: 35px;
  height: 35px;
  right: 10px;
  top: 10px;
  z-index: 2000;
}

/*--------------------------------------------------------------
# scroller
--------------------------------------------------------------*/

#main {
  background-color: white;
  /*hack: //todo achang bg to check padding / margin*/
  // margin: -55px 0 0 0;
  margin: 0;
  /*hack: //todo adjust after sidebar*/
  padding: 0 0 0 0;
  display: block;
}

section {
  background-color: white;
  /*hack: //todo achang bg to check padding / margin*/
  margin: 0 0 0 0;
  /*hack: //todo adjust after sidebar*/
  padding: 0 0 0 0;
}

#scroller {
  margin: 0 0 0 var(--scrollerMarginLeft);
  padding: 0 0 0 0;
  /*update sidebar width as well*/
}

#scroller.header-scrolled {
  padding: 0 0;
  height: 0;
  transition: all 0.5s;
}

@media only screen and (max-width: 767px) {
  /*--[ Mobile styles go here]---------------------------*/
  /* lang switch */
  .lang-switch {
    position: fixed;
    top: 55px;
    left: 0px;
    z-index: 10;
  }
  #main {
    margin: 0 0 0 0;
    /*hack: //TODO adjust after sidebar, check this*/

  }
  #scroller {
    margin: 0 0 0 0;

    /*update sidebar width as well*/
  }
  .ReactModalPortal div div.terms-div {
    background-color: white;
  }
  #msg404 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 80%;
    text-align: center;

  }
}

.owl-item {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.owl-item:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}


div.special-img-hover {
  overflow: hidden;
}

div.special-img-hover img {
  transition: all 0.8s;
}

div.special-img-hover img:hover {
  // transform: scale(1.1);
  transform: scale(1.04);
  /* filter: contrast(150%); */
  //transition: all 1.6s;
  transition: all 0.8s;
}




@media screen and (max-width: 767px) {
  .owl-carousel .owl-stage-outer {
    margin: 0 2px;
  }
  .owl-carousel button.owl-dot {
    outline: 0;
  }
  .owl-theme .owl-dots .owl-dot span {
    margin: 0!important;
  }
}